(function($) {

	/* Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true,
		'alwaysShowNavOnTouchDevices': true
  })



	var swiper = new Swiper('.hero-swiper', {
	  // Optional parameters
	  direction: 'horizontal',
	  loop: true,
		speed: 2000,
		autoplay: {
			delay: 7000,
		},
		slidesPerView: 1,
		navigation: {
	    nextEl: '.swiper-button-next',
	    prevEl: '.swiper-button-prev',
	  },
	});



	$(document).ready(function() {
	  $('select').niceSelect();
	});



	function isScrolledIntoView(elem) {
	    var docViewTop = $(window).scrollTop();
	    var docViewBottom = docViewTop + $(window).height();

	    var elemTop = $(elem).offset().top;
	    var elemBottom = elemTop + $(elem).height();

	    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	}

	$(window).scroll(function () {
	    $('.animateicon').each(function () {
	        if (isScrolledIntoView(this) === true) {
	            $(this).addClass('visible');
	        }
	    });

	});


	// Script
	var lastScroll = 0;
	$(window).on('scroll',function() {
	    var scroll = $(window).scrollTop();
	    if(lastScroll - scroll > 0) {
	        $("header").addClass("scrolling-up");
	    } else {
	        $("header").removeClass("scrolling-up");
	    }
	    lastScroll = scroll;
	});

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();

		// Change header position
		if(scroll > 50){
			$('header').addClass('scrolling');
		}else{
			$('header').removeClass('scrolling');
		}

		// Change header position
		if(scroll < 350){
			$('header').removeClass('scrolling-up');
		}

	});



	/* Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// Open

	$( ".lightbox-button" ).click(function() {
		// stopScroll();
		var lightbox_id = $( this ).prop('id');
		$( "#" + lightbox_id + "-content" ).addClass("active");
		if (lightbox_id == 'lightbox-search'){
			setTimeout(searchFocus, 400);
		}
	});

	// Close

	function close_lightbox() {
		// startScroll();
		$( ".custom-lightbox" ).removeClass("active");
	}

	$( ".close-btn" ).click(function() {
		close_lightbox();
	});

	$(document).on('click', function(event) {
		if ($(event.target).has('.lightbox-content').length) {
			close_lightbox();
		}
	});

	/* Search Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// Search Focus on input

	function searchFocus(){
		$("input#s").focus();
	}






	/* To the top
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$(".back-to-top").click(function(){
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
  });



	/* Swipe Menu (Custom Moblie Menu)
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$('#mobile-menu').mmenu({
    extensions: ["border-full", "pagedim-black", "position-right"],
		offCanvas: {
				pageSelector: ".site-wrap"
		},
		scrollBugFix: {
    	fix:true,
    },
		navbars: {
		 "position": "bottom",
		 "content": [
		 ]
	}
	});




	$( ".scroll-down" ).click(function() {
		let pageHeight = window.innerHeight;
		window.scroll({top:pageHeight,behavior: 'smooth'});
	});



	/* Sticky Header
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// var headerHeight = $('.top-nav').outerHeight() + $('header').outerHeight();
	var headerHeight = $('header').outerHeight();

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		headerHeight = $('header').outerHeight() + $('header').outerHeight();

		if(scroll > headerHeight){
			$('header').addClass('Sticky');
			$('body').addClass('menu_is_sticky');
		}else{
			$('header').removeClass('Sticky');
			$('body').removeClass('menu_is_sticky');
		}

		if(scroll > 700){
			$('.back-to-top').addClass('visible');
		}else{
			$('.back-to-top').removeClass('visible');
		}

	});




	$( ".mburger-mobilemenu" ).click(function() {
		$(".mm-menu").removeClass('mm-menu_opened');
		$("html").removeAttr("class");
		$(".site-wrap").removeAttr("style");
	});

	$( ".mburger-normal" ).click(function() {
		$(".mm-menu").addClass('mm-menu_opened');
		$("html").addClass("mm-wrapper_opened");
		$("html").addClass("mm-wrapper_blocking");
		$("html").addClass("mm-wrapper_background");
		$("html").addClass("mm-wrapper_opening");
	});



	/* Accordion
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels1 = $('.accordion-block .item .answer').hide();
	var question1 = $('.accordion-block .item .question');

	$('.accordion-block .item .question a').click(function() {
		allPanels1.slideUp();
		question1.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".accordion-block .item .question:first").addClass('active');
	$(".accordion-block .item .answer:first").show();



	/* Document Library
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels2 = $('.document-library-block .category .files').hide();
	var trigger2 = $('.document-library-block .category .name');

	$('.document-library-block .category .name a').click(function() {
		allPanels2.slideUp();
		trigger2.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".document-library-block .category .name:first").addClass('active');
	$(".document-library-block .category .files:first").show();



	/* Button Block List Mobile
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels3 = $('.button-block-list-block .grid.mobile .grid-item .list').hide();
	var trigger3 = $('.button-block-list-block .grid.mobile .grid-item .title');

	$('.button-block-list-block .grid.mobile .grid-item .title a').click(function() {
		allPanels3.slideUp();
		trigger3.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".button-block-list-block .grid.mobile .grid-item .title:first").addClass('active');
	$(".button-block-list-block .grid.mobile .grid-item .list:first").show();



})( jQuery );
